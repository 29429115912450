import { statusMapping } from '@/core/enum/service';
import { ExpandedServiceAgent } from '@/core/model/Services';
import { getServicesAgent, interruptService } from '@/core/service/ServiceService';
import { reactive } from 'vue';

const state = reactive<{ servicesAgent: ExpandedServiceAgent[]; filteredServicesAgent: ExpandedServiceAgent[] }>({
    servicesAgent: [],
    filteredServicesAgent: [],
});


const actions = {
    async GetByAgent(agentId: string, slaStatus?: string) {
        const data = await getServicesAgent(agentId, slaStatus);
        state.servicesAgent = data.map(service => ({
            ...service,
            expanded: false,
        }));

        state.filteredServicesAgent = [...state.servicesAgent];
    },
    async InterruptService(id: string, reason: string) {
        const data = await interruptService(id, reason);
    }
};


const getters = {
    toggleExpanded(index: number) {
        if (state.servicesAgent && state.servicesAgent[index]) {
            state.servicesAgent[index].expanded = !state.servicesAgent[index].expanded;
        }
    },
};

export { state, actions, getters };
