<template>
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="100" height="100" rx="8" fill="#F6F7F9" />
        <path
            d="M50.0008 22.6191C42.827 22.6191 36.9948 28.4513 36.9948 35.6251C36.9948 42.662 42.4984 48.3572 49.6722 48.6037C49.8913 48.5763 50.1103 48.5763 50.2746 48.6037C50.3294 48.6037 50.3568 48.6037 50.4115 48.6037C50.4389 48.6037 50.4389 48.6037 50.4663 48.6037C57.4758 48.3572 62.9794 42.662 63.0068 35.6251C63.0068 28.4513 57.1746 22.6191 50.0008 22.6191Z"
            fill="#B2BBC7" />
        <path
            d="M63.9103 55.887C56.271 50.7941 43.8127 50.7941 36.1187 55.887C32.6413 58.2144 30.7246 61.3632 30.7246 64.731C30.7246 68.0989 32.6413 71.2203 36.0913 73.5203C39.9246 76.0941 44.9627 77.3811 50.0008 77.3811C55.0389 77.3811 60.077 76.0941 63.9103 73.5203C67.3603 71.193 69.277 68.0715 69.277 64.6763C69.2496 61.3084 67.3603 58.187 63.9103 55.887Z"
            fill="#B2BBC7" />
    </svg>
</template>