export function getDisplayStatus(status: string) {
    const statusLowerCase = status.toLowerCase();

    if (statusLowerCase.startsWith('aguardando')) {
        return 'Aguardando';
    }

    return status;
}

export function getStatusClass(status: string) {
    const statusLowerCase = status.toLowerCase();

    if (statusLowerCase === 'finalizado') {
        return 'status-finished';
    }
    if (statusLowerCase.startsWith('aguardando')) {
        return 'status-waiting';
    }
    if (statusLowerCase === 'em andamento') {
        return 'status-inservice';
    }
    if (statusLowerCase === 'cancelado') {
        return 'status-cancelled';
    }
    if (statusLowerCase === 'interrompido') {
        return 'status-interrupt';
    }

    return '';
}