import { http } from '../api';
import { Filters, PaginateRequest, PaginateResponse } from '../model';
import { CreateActivityRequest, Activity } from '../model/Activity';

const controller = 'activity';

export async function getByCategory(categoryId: string): Promise<Activity[]> {
  const { data } = await http.get<Activity[]>(`${controller}/category/${categoryId}`);
  return data;
}

export async function getPaginate(paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Activity>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Activity>>(`${controller}?limit=${paginate?.limit ? paginate.limit : 99999}&page=${paginate?.page ? paginate.page : 1}&showDisabled=true`, {
    params,
  });

  return { page: data.page, total: data.total, data: data.data, totalPages: data.totalPages, limit: data.limit };
}

export async function getBy(id: string): Promise<Activity> {
  const { data } = await http.get<Activity>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<Activity>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function update(id: string, payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<Activity>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function deleted(id: string, force?: boolean): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`, {
    data: {
      forceDelete: force,
    }
  });

  return status === 204;
}


export async function enable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  return data;
}

export async function disable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  return data;
}

function validateAndAppendAll(formData: FormData, payload: CreateActivityRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (item instanceof File) {
              formData.append(`${key}`, item);
            } else {
              formData.append(`${key}`, String(item));
            }
          });
        } else {
          formData.append(key, String(value));
        }
      }
    }
  }
}

