import axios from 'axios';
import { Authentication } from '../../store';
import Swal from 'sweetalert2';
import { iconErrorSwalfire } from '@/components/atoms/icons/iconsSwal';
import jwt_decode from 'jwt-decode';
import router from '@/router';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'cache',
  },
  withCredentials: true,
});

http.interceptors.request.use((config: any) => {
  if (config.headers === undefined) return;
  if (Authentication.state.value?.token) {
    const token = Authentication.state.value.token;
    if (!isTokenExpired(token)) {
      config.withCredentials = true;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      Authentication.actions.logout();
      router.push('/');
      return Promise.reject('Token expirado ou inválido, redirecionando para a página de login.');
    }
  }
  return config;
});

export const isTokenExpired = (token: string) => {
  const decodedToken: any = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};

const errors = {
  invalid_credentials: 'Credenciais Inválidas',
  missing_required_fields: 'Campos obrigatórios não preenchidos',
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const computedStyles = getComputedStyle(document.documentElement);
    const primaryColor = computedStyles.getPropertyValue('--primary-c600');
    const confirmButtonColor = computedStyles.getPropertyValue('--secondary-c800');

    let message = error?.response?.data?.message;
    if (Array.isArray(error?.response?.data)) {
      message = error?.response?.data[0].field;
    }
    if (error.response.status == 401) {
      Authentication.actions.logout();
      router.push('/');
      return Promise.reject('Token expirado ou inválido, redirecionando para a página de login.');
    } else if (error.response.status == 412) {
      return Promise.reject(error.response);
    } else if (error.response.status >= 500) {
      const errorMessage = error.response.data.replace(/^Unexpected Error: Error:\s*/, '');
      Swal.fire({
        title: `<p class="swalfire-title-2">Erro</p>`,
        text: errors[message] ?? message ?? errorMessage ?? 'Ocorreu um erro inesperado no sistema, por favor contacte o suporte',
        iconHtml: iconErrorSwalfire(primaryColor.trim(), '#FFFFFF'),
        confirmButtonText: 'Entendido',
        confirmButtonColor: confirmButtonColor.trim(),
        customClass: {
          confirmButton: 'swalfire-confirm-button',
        },
      });
    } else {
      Swal.fire({
        title: `<p class="swalfire-title-2">Erro</p>`,
        text: error.response.data,
        iconHtml: iconErrorSwalfire(primaryColor.trim(), '#FFFFFF'),
        confirmButtonText: 'Entendido',
        confirmButtonColor: confirmButtonColor.trim(),
        customClass: {
          confirmButton: 'swalfire-confirm-button',
        },
      });
    }

    return Promise.reject(error);
  }
);

export { http };
