import { http } from '../api';
import { Authentication } from '@/store';
import { Filters, PaginateRequest, PaginateResponse } from '../model';
import { OperationRequest, Operation } from '../model/Operation';

const controller = 'operation';

export async function getPaginate(paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Operation>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<PaginateResponse<Operation> | any>(`${controller}?limit=${paginate?.limit ? paginate.limit : 99999}&page=${paginate?.page ? paginate.page : 1}`, {
    params,
  });

  return { page: data.page, total: data.total ? data.total : data.length, data: data.data ? data.data : data, limit: data.limit ? data.limit : '', totalPages: data.totalPages ? data.totalPages : '' };
}

export async function getUsersByRoom(location: string): Promise<OperationUser[]> {
  const clientId = Authentication.getters.getUser.value?.company.clientId;
  const { data } = await http.get(`${controller}/company/${clientId}/location/${location}`);

  return data;
}

export type OperationUser = {
  doc: string;
  name: string;
}

export async function getBy(id: string): Promise<Operation> {
  const { data } = await http.get<Operation>(`${controller}/${id}`);
  return data;
}

export async function create(payload: OperationRequest): Promise<Operation> {
  const { data } = await http.post<Operation>(`${controller}`, payload);
  return data;
}

export async function update(id: string, payload: OperationRequest): Promise<Operation> {
  const { data } = await http.patch<Operation>(`${controller}/${id}`, { ...payload, id });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  return status === 204;
}
